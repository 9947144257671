import React from "react";
import "./HomepageBanner.css";
import { Link } from "react-router-dom";
function HomepageBanner({ link, image,title,subtitle,sImage }) {
  console.log(window.innerWidth)
  return (
    <div className="homepagebanner">
      <img src={window.innerWidth<='600' ? sImage : image} alt="banner" />
      <div className="homepagebanner_title">
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
      <div className="homepagebanner_div">
        <Link to="/contactus">ENQUIRE NoW</Link>
        <Link to={link}>EXPLORE NoW</Link>
      </div>
      <div className="homepagebanner_div_second">
        <p>*The pictures are for representation purpose only</p>
      </div>
    </div>
  );
}

export default HomepageBanner;
