import React from "react";
import "./HomeBottom.css";
import logo from "../../asset/logo.png";
function HomeBottom() {
  return (
    <div className="homebottom">
      <div className="homebottom_heading">
        <h3>Cool The Earth By One Degree</h3>
      </div>
      <div className="homebottom_para">
        <p>Technology Green Future</p>
      </div>
      <div className="homebottom_logo">
        <img
          src={logo}
          alt="logo"
          style={{
            cursor: "pointer",
            height: "2.7rem",
            objectFit: "contain",
          }}
        />
      </div>
    </div>
  );
}

export default HomeBottom;
